<template>
  <div class="about" id="about">
    <content1 />
    <content2 />
    <content3 />
    <content4 />
    <content5 />
    <content6 />
    <content7 />
  </div>
</template>
<script>
import $ from 'jquery'

export default {
  name: 'about',
  components: {
    'content1': () => import('./Content1.vue'),
    'content2': () => import('./Content2.vue'),
    'content3': () => import('./Content3.vue'),
    'content4': () => import('./Content4.vue'),
    'content5': () => import('./Content5.vue'),
    'content6': () => import('./Content6.vue'),
    'content7': () => import('./Content7.vue')
  },
  methods: {
    stickMenuabout () {
      $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
          $('header').addClass('onscroll')
          $('img.logo').css('display', 'block')
          $('img.logo-white').css('display', 'none')
          $('header').removeClass('about')
        } else {
          $('img.logo').css('display', 'none')
          $('img.logo-white').css('display', 'block')
          $('header').addClass('about')
          $('header').removeClass('onscroll')
        }
      })
    },

    stickMenu () {
      $('header').removeClass('about')
      $('img.logo').css('display', 'block')
      $('img.logo-white').css('display', 'none')
      $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
          $('header').addClass('onscroll')
          $('header').removeClass('about')
          $('img.logo').css('display', 'block')
          $('img.logo-white').css('display', 'none')
        } else {
          $('header').removeClass('onscroll')
          $('header').removeClass('about')
          $('img.logo').css('display', 'block')
          $('img.logo-white').css('display', 'none')
        }
      })
    }
  },
  beforeRouteLeave (to, from, next) {
    $('header').removeClass('about')
    this.stickMenu()
    next()
  },
  mounted () {
    $('img.logo').css('display', 'none')
    $('img.logo-white').css('display', 'block')
    $('header').addClass('about')
    this.stickMenuabout()

    var VueScrollTo = require('vue-scrollto');
    var element = location.hash

    if (location.hash) {
      this.$nextTick(() => {
        setTimeout(function() {
          VueScrollTo.scrollTo(element)
        }, 100)
      })
    }
  }
}
</script>

<style scoped lang='scss'>
  img.logo {
    display: none;
  }
</style>
